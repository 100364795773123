import config from '../config';
import { auth } from '../firebase/configfirebase';

export const authenticateUser = async (type, role) => {
  // type = 'login' nebo 'logout', role = 'student' nebo 'teacher' (nepovinné)
  if (!auth.currentUser) {
    console.error("Žádný uživatel není aktuálně přihlášen");
    return null; // Změna, vrací null místo false
  }
  try {
    const userToken = await auth.currentUser.getIdToken();
    const requestBody = { type };
    if (role) {
      requestBody.role = role;
    }

    const response = await fetch(`${config.API_URL}/api/authentication`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userToken}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Nepodařilo se komunikovat se serverem");
    }

    const data = await response.json();
    return data; // Vrátí kompletní data (např. { userID, role })
  } catch (error) {
    console.error("Chyba při komunikaci se serverem:", error);
    return null;
  }
};

