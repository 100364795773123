import config from '../../../config';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../../Backend/fetchData';
import '../../../style/Launched_test/Teacher_show_session.css';

const TeacherShowSession = () => {
  const { idTestSession, idTest } = useParams();
  const [students, setStudents] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [summary, setSummary] = useState([]);
  const [maxPoints, setMaxPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  // References to summary textareas
  const summaryRefs = useRef([]);

  let maxPointOfTest = maxPoints.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue), 0);

  useEffect(() => {
    const loadSessionData = async () => {
      try {
        const { data, error } = await fetchData(`${config.API_URL}/api/POST/sessionData`, { idTest, idTestSession });
        if (error) {
          setError(error);
        } else {
          setStudents(data.students || []);
          setQuestions(data.questions || []);
          setSummary(data.summary || []);
          setMaxPoints(data.maxPoints || []);
        }
      } catch (err) {
        setError('Chyba při načítání dat.');
      } finally {
        setLoading(false);
      }
    };
    loadSessionData();
  }, [idTest, idTestSession]);

  useEffect(() => {
    // Adjust the height of each textarea after loading summary data
    summaryRefs.current.forEach((textarea, index) => {
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    });
  }, [summary]);

  const handleSummaryChange = (index, value) => {
    const newSummary = [...summary];
    newSummary[index] = value;
    setSummary(newSummary);
    setHasChanges(true);
  };

  const adjustTextareaHeight = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleScoreChange = (studentIndex, questionIndex, value) => {
    const newStudents = [...students];
    newStudents[studentIndex].scores[questionIndex] = parseInt(value, 10);
    setStudents(newStudents);
    setHasChanges(true);
  };

  const hendleSaveData = async() =>{
    try {
      const response = await fetch(
        `${config.API_URL}/api/PUT/session_save`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({idTestSession, idTest, students, summary }),
        }
      );

      if (response.ok) {
        console.log("Data byla úspěšně odeslána na backend.");
      }
    } catch (error) {
      console.error("Chyba při ukládání dat na backend:", error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <p>Načítání dat...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <section className="teacher-session-container">
      <h2>Výsledky: {idTestSession}</h2>
      {hasChanges && (
        <button className="save-button" onClick={() => {hendleSaveData()}}>
          Uložit
        </button>
      )}
      <div className="table-wrapper">
        <table className="teacher-session-table">
          <thead>
            <tr>
              <th>Student</th>
              {questions.map((question, index) => (
                <th key={index}>{question}</th>
              ))}
              <th>Body</th>
            </tr>
            <tr>
              <td className="summary-row">Shrnutí</td>
              {summary.map((sum, index) => (
                <td key={index} className="summary-cell">
                  <textarea
                    ref={(el) => (summaryRefs.current[index] = el)} // Assign reference
                    value={sum}
                    onChange={(e) => handleSummaryChange(index, e.target.value)}
                    onInput={adjustTextareaHeight}
                    className="editable-input"
                  />
                </td>
              ))}
              <td></td>
            </tr>
          </thead>
          <tbody>
            {students.map((student, studentIndex) => (
              <tr key={student.studentID}>
                <td className="student-name">{student.studentName}</td>
                {questions.map((_, questionIndex) => {
                  const answer = student.answers?.[questionIndex] || '--';
                  const score = parseInt(student.scores?.[questionIndex] || 0);
                  const maxScore = parseInt(maxPoints[questionIndex] || 0);
                  let styleOfScore = "";
                  if (score >= maxScore) {
                    styleOfScore = "max-score";
                  } else if (score === 0) {
                    styleOfScore = "min-score";
                  } else {
                    styleOfScore = "normal-score";
                  }

                  return (
                    <td key={questionIndex} className="answer-cell">
                      <div>{answer}</div>
                      <div className={`score-text ${styleOfScore}`}>
                        <input
                          type="number"
                          value={score}
                          onChange={(e) => handleScoreChange(studentIndex, questionIndex, e.target.value)}
                          className={`editable-input ${styleOfScore}`}
                        /> / {maxScore}
                      </div>
                    </td>
                  );
                })}
                <td>{student.scores.reduce((accumulator, currentValue) => accumulator + currentValue, 0)}/{maxPointOfTest}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TeacherShowSession;
