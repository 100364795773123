// Launched_test_student.js
import "../../style/Launched_test/Launched_test_student.css";
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WebSocketContext } from '../../Backend/webSocketContext';

const LaunchedTestStudent = () => {
    const { idTestSession } = useParams();
    const { ws, addListener, removeListener } = useContext(WebSocketContext);
    const [idSession, setIdSession] = useState(idTestSession || '');
    const [studentName, setStudentName] = useState('');
    const [studentID, setStudentID] = useState(null);
    const [error, setError] = useState('');
    const [isSession, setIsSession] = useState(!!idTestSession);
    const [isWaiting, setIsWaiting] = useState(false);
    const [testEnded, setTestEnded] = useState(false);
    const navigate = useNavigate();

    const studentNameRef = useRef(studentName);
    const studentIDRef = useRef(studentID);

    useEffect(() => {
        studentNameRef.current = studentName;
    }, [studentName]);

    useEffect(() => {
        studentIDRef.current = studentID;
    }, [studentID]);

    const handleMessage = useCallback((data) => {
        if (data.type === 'questions') {
            localStorage.setItem("test", JSON.stringify(data.questions.sort((a, b) => a.order - b.order)));
            localStorage.setItem("TestName", JSON.stringify(data.testName));
            setStudentID(data.studentID);
            setIsWaiting(true);
        } else if (data.type === 'error') {
            setError(data.message);
        } else if (data.type === 'endTest') {
            setTestEnded(true);
        } else if (data.type === 'startTest') {
            navigate(`/normal_test_session/${idSession}/${studentIDRef.current}/${studentName}`);
        }
    }, [idSession, navigate, studentName]);

    useEffect(() => {
        if (!ws) return;

        addListener(handleMessage);

        const handleUnload = () => {
            if (studentIDRef.current && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                    type: 'disconnect',
                    sessionID: idSession,
                    studentName: studentNameRef.current,
                }));
            }
        };

        window.addEventListener("beforeunload", handleUnload);

        return () => {
            removeListener(handleMessage);
            window.removeEventListener("beforeunload", handleUnload);
        };
    }, [ws, idSession, addListener, removeListener, handleMessage]);

    const handleSessionSubmit = () => {
        // Validace, zda je idSession číslo a není prázdné
        if (idSession === '') {
            setError('Prosím, zadejte ID relace.');
            return;
        }

        const sessionNumber = Number(idSession);
        if (isNaN(sessionNumber) || !Number.isInteger(sessionNumber) || sessionNumber <= 0) {
            setError('ID relace musí být platné celé číslo.');
            return;
        }

        setError('');
        setIsSession(true);
    }

    const handleNameSubmit = () => {
        if (studentName.trim() === '') {
            setError('Prosím, zadejte své jméno.');
            return;
        }

        setError('');

        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({
                type: 'student_connect',
                sessionID: idSession,
                studentName,
            }));
        } else {
            setError('Připojení k serveru selhalo.');
        }
    };

    return (
        <section className="student-container">
            {!isSession  ? (
                <div className="session-entry">
                    <h2>Zadejte číslo relace</h2>
                    <input
                        type="number"
                        value={idSession}
                        onChange={(e) => setIdSession(e.target.value)}
                        placeholder="Zadejte číslo relace"
                        min="1"
                    />
                    <button onClick={handleSessionSubmit}>Připojit se</button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            ) : testEnded ? (
                <div className="end-test-message">
                    <h2>Test byl ukončen učitelem.</h2>
                </div>
            ) : !isWaiting ? (
                <div className="name-entry">
                    <h2>Připojte se k relaci</h2>
                    <input
                        type="text"
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                        placeholder="Zadejte své jméno"
                    />
                    <button onClick={handleNameSubmit}>Připojit se</button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            ) : (
                <div className="waiting-screen">
                    <h2>Děkujeme, {studentName}!</h2>
                    <p>Čekejte, dokud učitel test nespustí...</p>
                    <div className="loading-indicator"></div>
                </div>
            )}
        </section>
    );
};

export default LaunchedTestStudent;
