import { useState, useContext, useEffect } from "react";
import { FcGoogle } from "react-icons/fc"; 
import "../style/Registration.css";
import { AuthContext } from "../firebase/AuthContext";
import { auth, googleProvider } from "../firebase/configfirebase";
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification } from "firebase/auth";

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("student"); // Výchozí role je student
  const { user } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (user) {
      navigate('/'); // Přesměrování po úspěšném přihlášení
    }
  }, [user, navigate]);

  const handleGoogleRegister = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      localStorage.setItem('userRole', role); // Uložíme roli do localStorage
      navigate('/');
    } catch (error) {
      setError("Chyba při registraci pomocí Google: " + error.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Odeslání potvrzovacího e-mailu
      await sendEmailVerification(user);
      setSuccess("Registrace byla úspěšná! Zkontrolujte svůj e-mail pro potvrzení účtu.");

      localStorage.setItem('userRole', role); // Uložíme roli do localStorage

      navigate('/');
    } catch (error) {
      setError("Chyba při registraci: " + error.message);
    }
  };

  return (
    <section>
      <div className="register-section">
        <h1 className="register-heading">Registrace</h1>
        <form onSubmit={handleRegister} className="register-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="register-input"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Heslo"
            className="register-input"
            required
          />
          <div className="role-selection">
            <input
              type="radio"
              id="student"
              name="role"
              value="student"
              checked={role === "student"}
              onChange={(e) => setRole(e.target.value)}
            />
            <label htmlFor="student">Žák</label>

            <input
              type="radio"
              id="teacher"
              name="role"
              value="teacher"
              checked={role === "teacher"}
              onChange={(e) => setRole(e.target.value)}
            />
            <label htmlFor="teacher">Učitel</label>
          </div>
          <button type="submit" className="register-button">Registrovat se</button> 
        </form>
        {error && <p className="register-error">{error}</p>}
        {success && <p className="register-success">{success}</p>}
        <button onClick={handleGoogleRegister} className="google-register-button">
          <FcGoogle className="google-icon" />
          Registrovat se přes Google
        </button>
      </div>
    </section>
  );
};

export default Register;
