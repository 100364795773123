import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Funkce pro nahrání obrázku do Firebase Storage
export const uploadImageToStorage = async (file, path) => {
  const storage = getStorage(); // Získání instance Storage
  const storageRef = ref(storage, path); // Vytvoření referenční cesty ve Storage

  try {
    // Nahrání souboru
    const snapshot = await uploadBytes(storageRef, file);
    console.log("Nahrání bylo úspěšné:", snapshot);

    // Získání URL nahraného obrázku
    const url = await getDownloadURL(storageRef);
    console.log("URL obrázku:", url);
    return url;
  } catch (error) {
    console.error("Chyba při nahrávání souboru:", error);
    throw error;
  }
};
