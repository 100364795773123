import React, { useReducer, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import { closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import { fetchData } from "../../Backend/fetchData";
import CompQuestion from "../../Component/Parts/CompQuestion";
import CompTestEdit from "../../Component/Parts/CompTestEdit";
import SessionsOfTest from "./Sessions_of_test";
import "./../../style/Test.css";
import { SlPlus } from "react-icons/sl";
import config from "../../config";
import { v4 as uuidv4 } from "uuid";

// Definice počátečního stavu
const initialState = {
  testInfo: {
    TestName: "",
    TestDescription: "",
    TestTime: 0,
    testID: "",
  },
  questions: [],
  sessions: [],
  loading: true,
  error: null,
};

// Definice reduceru
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        testInfo: {
          TestName: action.payload.TestName,
          TestDescription: action.payload.TestDescription,
          TestTime: action.payload.TestTime,
          testID: action.payload.testID,
        },
        questions: action.payload.questions,
        sessions: action.payload.sessions,
        loading: false,
        error: null,
      };
    case "FETCH_ERROR":
      return { ...state, loading: false, error: action.payload };
    case "ADD_QUESTION":
      return {
        ...state,
        questions: [...state.questions, action.payload],
      };
    case "DELETE_QUESTION":
      return {
        ...state,
        questions: state.questions
          .filter((q) => q.id !== action.payload)
          .map((q, index) => ({ ...q, order: index + 1 })),
      };
    case "UPDATE_QUESTION":
      return {
        ...state,
        questions: state.questions.map((q) =>
          q.id === action.payload.id ? { ...q, ...action.payload.data } : q
        ),
      };
    case "REORDER_QUESTIONS":
      return {
        ...state,
        questions: action.payload.map((q, index) => ({ ...q, order: index + 1 })),
      };
    case "UPDATE_TEST_INFO":
      return {
        ...state,
        testInfo: { ...state.testInfo, ...action.payload },
      };
    default:
      return state;
  }
};

const OneTest = () => {
  const { IdTest } = useParams();
  const location = useLocation();
  const { TestName, TestDescription, TestTime, testID } = location.state || {};

  const [state, dispatch] = useReducer(reducer, initialState);
  const { testInfo, questions, sessions, loading, error } = state;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Načtení dat testu při načtení komponenty
  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const { data, error } = await fetchData(`${config.API_URL}/api/POST/questions`, { test_ID: testID });
        if (error) {
          dispatch({ type: "FETCH_ERROR", payload: error });
        } else {
          const sortedQuestions = data.questions
            .map((q, index) => ({
              ...q,
              order: q.order ?? index + 1,
            }))
            .sort((a, b) => a.order - b.order);
          dispatch({
            type: "FETCH_SUCCESS",
            payload: {
              TestName,
              TestDescription,
              TestTime,
              testID,
              questions: sortedQuestions,
              sessions: data.sessions,
            },
          });
        }
      } catch (err) {
        dispatch({ type: "FETCH_ERROR", payload: err.message });
      }
    };

    fetchTestData();
  }, [IdTest, testID, TestName, TestDescription, TestTime]);

  const handleAddQuestion = () => {
    const newQuestion = {
      id: uuidv4(),
      question: "Nová otázka?",
      description: "Popis otázky",
      answers: ["odpověď 1", "odpověď 2"],
      points: 5,
      testID: testInfo.testID,
      order: questions.length + 1,
    };
    dispatch({ type: "ADD_QUESTION", payload: newQuestion });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = questions.findIndex((item) => item.id === active.id);
      const newIndex = questions.findIndex((item) => item.id === over.id);

      const newQuestions = arrayMove(questions, oldIndex, newIndex);
      dispatch({ type: "REORDER_QUESTIONS", payload: newQuestions });
    }
  };

  if (loading) return <p>Načítání otázek...</p>;
  if (error) return <p>Chyba při načítání otázek: {error}</p>;

  return (
    <section>
      <SessionsOfTest sessionsData={sessions} testID={IdTest} />
      <CompTestEdit
        testInfo={testInfo}
        questions={questions}
        dispatch={dispatch}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={questions.map((item) => item.id)}
          strategy={rectSortingStrategy}
        >
          <div className="All-Questions">
            {questions
              .sort((a, b) => a.order - b.order)
              .map((oneQuestion) => (
                <SortableItem key={oneQuestion.id} id={oneQuestion.id}>
                  <CompQuestion
                    oneQuestion={oneQuestion}
                    testID={testInfo.testID}
                    dispatch={dispatch}
                  />
                </SortableItem>
              ))}
            <div
              key="new"
              className="One-Questions newQuestion-box"
              onClick={handleAddQuestion}
            >
              <SlPlus className="newQuestion" />
            </div>
          </div>
        </SortableContext>
      </DndContext>
    </section>
  );
};

export default OneTest;
