import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { WebSocketContext } from '../../../Backend/webSocketContext';
import "../../../style/Launched_test/Teacher_test_monitor.css";

const TeacherTestMonitor = () => {
    const { idTestSession } = useParams();
    const { ws, addListener, removeListener } = useContext(WebSocketContext);
    const [students, setStudents] = useState([]);
    const [studentsAnswers, setStudentsAnswers] = useState({});
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [answersVisible, setAnswersVisible] = useState(false);
    const [testName, setTestName] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedTestData = localStorage.getItem(idTestSession);
        if (storedTestData) {
            try {
                const parsedData = JSON.parse(storedTestData);
                setTestName(parsedData.testInfo.TestName);
            } catch (e) {
                console.error("Chyba při parsování dat z localStorage:", e);
                setError("Chyba při načítání dat testu. Kontaktujte administrátora.");
            }
        } else {
            setError("Testová data nejsou k dispozici. Obnovte stránku nebo kontaktujte administrátora.");
        }
    }, [idTestSession]);

    useEffect(() => {
        if (error) {
            alert(error);
            navigate('/');
        }
    }, [error, navigate]);

    const handleMessage = (data) => {
        if (data.type === 'update') {
            if (data.students && Array.isArray(data.students)) {
                setStudents(prevStudents => {
                    const updatedStudents = [...prevStudents];
                    data.students.forEach(newStudent => {
                        if (!prevStudents.some(student => String(student.id) === String(newStudent.id))) {
                            updatedStudents.push(newStudent);
                        }
                    });
                    return updatedStudents;
                });
            }
        } else if (data.type === 'studentSubmitted') {
            setStudentsAnswers(prevAnswers => ({
                ...prevAnswers,
                [data.studentID]: data.answers,
            }));
            setStudents(prevStudents => {
                if (!prevStudents.some(student => String(student.id) === String(data.studentID))) {
                    const newStudent = {
                        id: data.studentID,
                        name: data.studentName || `Student ${data.studentID}`
                    };
                    return [...prevStudents, newStudent];
                }
                return prevStudents;
            });
        } else if (data.type === 'testQuestions') {
            setNumberOfQuestions(data.numberOfQuestions);
        } else if (data.type === 'error') {
            console.error("WebSocket chyba:", data.message);
        }
    };

    useEffect(() => {
        if (!ws) return;

        addListener(handleMessage);

        const handleOpen = () => {
            ws.send(JSON.stringify({ type: 'teacher_connect', sessionID: idTestSession }));
            ws.send(JSON.stringify({ type: 'requestTestInfo', sessionID: idTestSession }));
        };

        if (ws.readyState === WebSocket.OPEN) {
            handleOpen();
        } else {
            ws.addEventListener('open', handleOpen);
        }

        return () => {
            removeListener(handleMessage);
            ws.removeEventListener('open', handleOpen);
        };
    }, [ws, idTestSession, addListener, removeListener]);

    useEffect(() => {
        console.log("Students:", students);
        console.log("StudentsAnswers:", studentsAnswers);
    }, [students, studentsAnswers]);

    const endTest = () => {
        if (ws) {
            ws.send(JSON.stringify({ type: 'endTest', sessionID: idTestSession }));
        }
        localStorage.removeItem(idTestSession);
        navigate('/');
    }

    return (
        <div className="teacher-test-monitor">
            <h2>Sledování testu - {testName}</h2>
            <button onClick={() => setAnswersVisible(prev => !prev)}>
                {answersVisible ? 'Skrýt odpovědi' : 'Zobrazit odpovědi'}
            </button>
            <button onClick={endTest}>
                Ukončit test
            </button>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Jméno studenta</th>
                            {[...Array(numberOfQuestions)].map((_, index) => (
                                <th key={index}>Otázka {index + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student) => {
                            const studentIdStr = String(student.id);
                            const studentAnswers = studentsAnswers[studentIdStr] || [];
                            return (
                                <tr key={student.id}>
                                    <td>{student.name}</td>
                                    {[...Array(numberOfQuestions)].map((_, index) => (
                                        <td key={index}>
                                            {answersVisible
                                                ? (studentAnswers[index] || '--')
                                                : '.....'}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TeacherTestMonitor;
