import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Abouth from "./pages/Abouth";
import Login from "./pages/Login";
import Register from "./pages/Register";
import SharedLayout from "./pages/SharedLayout";
import Err from "./pages/Err";
import AllTests from "./pages/AllTests";
import OneTest from "./Component/Parts/OneTest";
import LaunchedTestTeacher from "./pages/launched_test/Launched_test_teacher";
import LaunchedTestStudent from "./pages/launched_test/Launched_test_student";
import NormalTestSession from "./pages/launched_test/Normal_test/Normal_test_session";
import TeacherShowSession from "./pages/launched_test/Normal_test/Teacher_show_session";
import TeacherTestMonitor from "./pages/launched_test/Normal_test/Teacher_Test_Monitor";
import ProfilePage from "./pages/ProfilePage";
import { AuthProvider } from "./firebase/AuthContext";
import { WebSocketProvider } from "./Backend/webSocketContext";

const App = () => {
  return (
    <AuthProvider>
      <WebSocketProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SharedLayout />}>
              <Route index element={<Home />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/Abouth" element={<Abouth />} />
              <Route path="/AllTests" element={<AllTests />} />
              <Route path="/AllTests/:IdTest" element={<OneTest />} />
              <Route path="/Launched_test_teacher/:idTest/:idTestSession" element={<LaunchedTestTeacher />} />
              <Route path="/Launched_test_student/:idTestSession?" element={<LaunchedTestStudent />} />
              <Route path="/normal_test_session/:idTestSession/:idStudent/:studentName" element={<NormalTestSession />} />
              <Route path="/teacher_test_monitor/:idTestSession/:idTest" element={<TeacherTestMonitor />} />
              <Route path="/teacher_show_Session/:idTestSession/:idTest" element={<TeacherShowSession/>} />
              <Route path="/Profile" element={<ProfilePage/>}/>
              <Route path="*" element={<Err />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </WebSocketProvider>
    </AuthProvider>
  );
};

export default App;
