import { Link } from "react-router-dom"
import { FaTimes } from "react-icons/fa";

const CompTests = (props) => {


  return (
    <div key={props.OneTest.id} className="One-Test" >
      <button className="delete-test" onClick={()=>props.deleteTest(props.OneTest.id)}><FaTimes/></button>
      <h2>{props.OneTest.TestName} </h2>
      <p>{props.OneTest.TestDescription} </p>
      <p>{TimetoText(parseInt(props.OneTest.TestTime))} </p>
      <Link to={`/AllTests/${props.OneTest.id}`} state={{ TestName: props.OneTest.TestName, TestDescription: props.OneTest.TestDescription,TestTime: props.OneTest.TestTime, testID: props.OneTest.id }}>Otevřít test</Link> {/*alt+7 = ` */}
    </div>
  )
}

const TimetoText = (Times) => {

    if (Times === 0 ){
       return "Neomezený"
    }
    else
    {
        return`${Times} minut`
    }
}

export default CompTests
