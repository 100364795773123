import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../style/componenty/Sessions_of_test.css';

const SessionsOfTest = ({ sessionsData, testID }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const sessionsListRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Pomocná funkce pro převod Firestore timestamp na datum
  const convertTimestampToDate = (timestamp) => {
    if (timestamp && timestamp._seconds) {
      return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
    }
    return null;
  };

  const openSession = (sessionID) => {
    navigate(`/teacher_show_Session/${sessionID}/${testID}`);
  };

  useEffect(() => {
    const sessionsList = sessionsListRef.current;
    if (sessionsList) {
      if (isExpanded) {
        const scrollHeight = sessionsList.scrollHeight;
        sessionsList.style.height = `${scrollHeight}px`;
        sessionsList.style.opacity = '1';
      } else {
        sessionsList.style.height = '0px';
        sessionsList.style.opacity = '0';
      }
    }
  }, [isExpanded, sessionsData]);

  // Řazení podle času ukončení (endTime)
  const sortedSessions = [...sessionsData].sort((a, b) => {
    const dateA = convertTimestampToDate(a.endTime);
    const dateB = convertTimestampToDate(b.endTime);
    return dateB - dateA; // Řadí sestupně (nejnovější nahoře)
  });

  return (
    <div className="sessions-container">
      <button className="expand-button" onClick={toggleExpand}>
        <span>Vyplněné testy</span>
        <span className="icon">
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>

      <div
        className={`sessions-list ${isExpanded ? 'expand' : ''}`}
        ref={sessionsListRef}
      >
        {sortedSessions.length > 0 ? (
          sortedSessions.map((session) => {
            const endTime = convertTimestampToDate(session.endTime);
            return (
              <div key={session.sessionID} className="session-item">
                <p><strong>ID session:</strong> {session.sessionID}</p>
                <p><strong>Čas ukončení:</strong> {endTime ? endTime.toLocaleString() : 'Neznámý čas'}</p>
                <p><strong>Počet studentů:</strong> {session.studentCount}</p>
                <button
                  className="open-session-button"
                  onClick={() => openSession(session.sessionID)}
                >
                  Otevřít
                </button>
              </div>
            );
          })
        ) : (
          <p>Žádné dokončené testy</p>
        )}
      </div>
    </div>
  );
};

export default SessionsOfTest;
