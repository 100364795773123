// Launched_test_teacher.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import OneConnectedStudent from './Component/one_connected_student';
import { WebSocketContext } from '../../Backend/webSocketContext';
import "../../style/Launched_test/Launched_test_teacher.css";
import config from '../../config';


const LaunchedTestTeacher = () => {
    const { idTestSession, idTest } = useParams();
    const [students, setStudents] = useState([]);
    const { ws, addListener, removeListener } = useContext(WebSocketContext);
    const navigate = useNavigate();

    const handleMessage = useCallback((data) => {
        if (data.type === 'update') {
            setStudents(data.students);
            console.log("Aktualizovaný seznam studentů:", data.students);
        } else if (data.type === 'error') {
            console.error("WebSocket chyba:", data.message);
        }
    }, []);

    useEffect(() => {
        if (!ws) return;

        addListener(handleMessage);

        const handleOpen = () => {
            ws.send(JSON.stringify({ type: 'teacher_connect', sessionID: idTestSession }));
            console.log(`Učitel připojen k session ${idTestSession}`);
        };

        if (ws.readyState === WebSocket.OPEN) {
            handleOpen();
        } else {
            ws.addEventListener('open', handleOpen);
        }

        const endTest = () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ type: 'endTest', sessionID: idTestSession }));
            }
        };

        window.addEventListener('beforeunload', endTest);

        return () => {
            removeListener(handleMessage);
            ws.removeEventListener('open', handleOpen);
            window.removeEventListener('beforeunload', endTest);
        };
    }, [ws, idTestSession, addListener, removeListener, handleMessage]);

    const handleStartTest = () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: 'startTest', sessionID: idTestSession }));
            console.log('Test spuštěn');

            // Navigace na novou komponentu
            navigate(`/teacher_test_monitor/${idTestSession}/${idTest}`);
        }
    };

    return (
        <section>
            <div className='Launched-test-header'>
                <h2>Můžeš se připojit pomocí: {idTestSession}</h2>
                <QRCodeSVG value={`${config.WEB_URL}/Launched_test_student/${idTestSession}`} size={200} level={"L"} />
                <a href={`${config.WEB_URL}/Launched_test_student/${idTestSession}`} className="test-link">
                    Testovací link pro studenty
                </a>
                <button onClick={handleStartTest} className="start-test-button">
                    Spustit test
                </button>
            </div>
            <div className='all-connected-student'>
                {students.map((oneStudent) => (
                    <OneConnectedStudent
                        oneStudent={oneStudent.name}
                        studentID={oneStudent.id}
                        key={oneStudent.id}
                    />
                ))}
            </div>
        </section>
    );
};

export default LaunchedTestTeacher;
