import '../style/Home.css';

const Home = () => {
  return (
    <section className="home-container">
      <div className="hero-section">
        <h1 className="hero-title">Automatizované opravování testů pomocí AI</h1>
        <p className="hero-description">
          Revoluční webová aplikace pro rychlé, efektivní a přesné hodnocení studentských testů s využitím nejnovější technologie umělé inteligence.
        </p>
        <button className="cta-button">Začněte hned</button>
      </div>
      <div id="features" className="features-section">
        <h2>Co nabízíme</h2>
        <div className="features">
          <div className="feature">
            <h3>Rychlé hodnocení</h3>
            <p>Opravujte testy během několika vteřin, a to s maximální přesností.</p>
          </div>
          <div className="feature">
            <h3>Přizpůsobení vašim potřebám</h3>
            <p>Nastavte přísnost hodnocení a parametry dle potřeb vašich studentů.</p>
          </div>
          <div className="feature">
            <h3>Pokročilé analýzy</h3>
            <p>Získejte podrobný pohled na výsledky testů a pokrok studentů.</p>
          </div>
        </div>
      </div>
      <div id="testimonials" className="testimonial-section">
        <h2>Co říkají naši uživatelé</h2>
        <p>
          "Tato aplikace mi ušetřila hodiny opravování testů a poskytla mým studentům rychlou zpětnou vazbu." - Učitelka Jana
        </p>
      </div>
    </section>
  );
};

export default Home;
