import React, { useState, useEffect, useCallback } from "react";
import { FaTimes } from "react-icons/fa";

const CompQuestion = ({ oneQuestion, testID, dispatch }) => {
  const [questionData, setQuestionData] = useState({
    question: oneQuestion.question,
    description: oneQuestion.description,
    points: oneQuestion.points,
    answers: oneQuestion.answers,
  });
  const [isEditing, setIsEditing] = useState({
    question: false,
    description: false,
    points: false,
    answers: false,
  });
  const [answerInput, setAnswerInput] = useState(oneQuestion.answers.join("; "));

  // Aktualizace stavu při změně props
  useEffect(() => {
    setQuestionData({
      question: oneQuestion.question,
      description: oneQuestion.description,
      points: oneQuestion.points,
      answers: oneQuestion.answers,
    });
    setAnswerInput(oneQuestion.answers.join("; "));
  }, [oneQuestion]);

  // Funkce pro aktualizaci otázky v reduceru
  const updateQuestion = useCallback(
    (field, value) => {
      dispatch({
        type: "UPDATE_QUESTION",
        payload: {
          id: oneQuestion.id,
          data: { [field]: value },
        },
      });
    },
    [dispatch, oneQuestion.id]
  );

  // Handler pro dvojklik a editaci
  const handleDoubleClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  // Handler pro ztrátu focusu a uložení změn
  const handleBlur = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
    if (field === "answers") {
      const updatedAnswers = answerInput
        .split(";")
        .map((answer) => answer.trim())
        .filter((answer) => answer);
      updateQuestion("answers", updatedAnswers);
    }
  };

  // Handler pro změnu vstupu
  const handleChange = (field, value) => {
    setQuestionData((prevData) => ({ ...prevData, [field]: value }));
    updateQuestion(field, value);
  };

  return (
    <div className="comp-question">
      {isEditing.question ? (
        <input
          type="text"
          value={questionData.question}
          onChange={(e) => handleChange("question", e.target.value)}
          onBlur={() => handleBlur("question")}
        />
      ) : (
        <p className="One-Questions-Question" onDoubleClick={() => handleDoubleClick("question")}>
          {questionData.question}
        </p>
      )}

      {isEditing.description ? (
        <input
          type="text"
          value={questionData.description}
          onChange={(e) => handleChange("description", e.target.value)}
          onBlur={() => handleBlur("description")}
        />
      ) : (
        <p className="One-Questions-Description" onDoubleClick={() => handleDoubleClick("description")}>
          {questionData.description}
        </p>
      )}

      <div className="One-Questions-Points" onDoubleClick={() => handleDoubleClick("points")}>
        <p>Počet bodů:</p>
        {isEditing.points ? (
          <input
            type="number"
            value={questionData.points}
            onChange={(e) => handleChange("points", parseInt(e.target.value, 10) || 0)}
            onBlur={() => handleBlur("points")}
          />
        ) : (
          <p>{questionData.points}</p>
        )}
      </div>

      <div className="One-Questions-Answer" onDoubleClick={() => handleDoubleClick("answers")}>
        <p>Odpovědi:</p>
        {isEditing.answers ? (
          <textarea
            value={answerInput}
            onChange={(e) => setAnswerInput(e.target.value)}
            onBlur={() => handleBlur("answers")}
          />
        ) : (
          <div className="Answers">
            {questionData.answers.map((oneAnswer, index) => (
              <p className="Answer" key={index}>
                {oneAnswer}
              </p>
            ))}
          </div>
        )}
      </div>

      <div className="question-actions">
        <button
          className="btn-delete"
          onClick={() => dispatch({ type: "DELETE_QUESTION", payload: oneQuestion.id })}
        >
          <FaTimes /> Smazat
        </button>
      </div>
    </div>
  );
};

export default React.memo(CompQuestion);
