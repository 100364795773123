import {Outlet} from "react-router-dom"
import Navbar from "../Component/Navbar"
import Footer from "../Component/Footer"

const SharedLayout = () => {
  return (
    <>
        <Navbar/>
        <Outlet/>
        <Footer/>
    </>
  )
}

export default SharedLayout
