// Importy z Firebase verze 9 (modulární přístup)
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDkdiWx7aa36Zb9G-1pWDXrPNuof0x7oqs",
  authDomain: "testy-ai.firebaseapp.com",
  projectId: "testy-ai",
  storageBucket: "testy-ai.firebasestorage.app",
  messagingSenderId: "633244659403",
  appId: "1:633244659403:web:d37ef59defb95be456354e"
};

// Kontrola, zda již Firebase aplikace existuje
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

// Inicializace Firestore
export const databaseFirestore = getFirestore(app);

// Inicializace Firebase autentizace a Google Provider
export const auth = getAuth(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();