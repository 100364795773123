import "../style/Footer.css"
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Moje Stránka. Všechna práva vyhrazena.</p>
        <div className="footer-links">
          <a href="#about">O nás</a>
          <a href="#contact">Kontakt</a>
          <a href="#privacy">Zásady ochrany soukromí</a>
        </div>
        <div className="footer-social">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
