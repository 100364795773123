import React, { createContext, useState, useEffect } from 'react';
import { auth } from './configfirebase';
import { signOut, onAuthStateChanged } from "firebase/auth";
import { authenticateUser } from '../Backend/authenticateUser'; // Importujeme funkci

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null); // Přidáme stav pro roli uživatele

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const storedRole = localStorage.getItem('userRole');
        const authData = await authenticateUser('login', storedRole); // Získáme roli z backendu
        setUser(currentUser);
        setRole(authData?.role || null); // Nastavíme roli uživatele
        if (storedRole) {
          localStorage.removeItem('userRole');
        }
      } else {
        setUser(null);
        setRole(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const logOut = async () => {
    try {
      await authenticateUser('logout'); // Informujeme server o odhlášení
      await signOut(auth);
      setUser(null);
      setRole(null); // Resetujeme roli
    } catch (error) {
      console.error("Chyba při odhlašování:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, role, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

