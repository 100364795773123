// WebSocketContext.js
import config from '../config';
import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const [listeners, setListeners] = useState([]);
    const listenersRef = useRef(listeners);

    // Aktualizace listenersRef při změně listeners
    useEffect(() => {
        listenersRef.current = listeners;
    }, [listeners]);

    useEffect(() => {
        const websocket = new WebSocket(config.WEBSOCKET_URL);
        setWs(websocket);

        websocket.onopen = () => {
            console.log("WebSocket připojení navázáno");
        };

        websocket.onerror = (error) => {
            console.error("WebSocket chyba:", error);
        };

        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            listenersRef.current.forEach((listener) => {
                listener(data);
            });
        };

        websocket.onclose = () => {
            console.log("WebSocket připojení uzavřeno");
            setWs(null);
        };

        return () => {
            websocket.close();
        };
    }, []);

    // Memoizované funkce
    const addListener = useCallback((listener) => {
        setListeners((prevListeners) => [...prevListeners, listener]);
    }, []);

    const removeListener = useCallback((listener) => {
        setListeners((prevListeners) => prevListeners.filter((l) => l !== listener));
    }, []);

    return (
        <WebSocketContext.Provider value={{ ws, addListener, removeListener }}>
            {children}
        </WebSocketContext.Provider>
    );
};
