import { useState, useContext, useEffect } from "react";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebase/configfirebase";
import { FcGoogle } from "react-icons/fc"; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../firebase/AuthContext";
import "../style/Login.css"; // Import stylování

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/'); // Přesměrování po přihlášení
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError("Chyba při přihlášení: " + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/');
    } catch (error) {
      setError("Chyba při přihlášení pomocí Google: " + error.message);
    }
  };

  return (
    <section>
      <div className="login-section">
        <h1 className="login-heading">Přihlášení</h1>
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="login-input"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Heslo"
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Přihlásit se</button>
        </form>
        {error && <p className="login-error">{error}</p>}
        <button onClick={handleGoogleLogin} className="google-login-button">
          <FcGoogle className="google-icon" /> Přihlásit se přes Google
        </button>
      </div>
    </section>
  );
};

export default Login;

