// Navbar.js
import { NavLink } from "react-router-dom";
import "../style/Navbar.css";
import { useContext, useState } from "react";
import { AuthContext } from "../firebase/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; // Import ikon
import { ReactComponent as LogoFull } from "../images/Logo/logo_full.svg"
import ProfilNavbar from "./Parts/Profil_navbar";

const Navbar = () => {
  const { user, logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Stav pro mobilní menu

  const handleLogout = () => {
    logOut();
    navigate('/');
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="navbar-logo">
            <NavLink to="/"><LogoFull className="Logo-full" /></NavLink>
          </div>
          <div className="navbar-links">
            <NavLink to="/" className={({ isActive }) => ActiveName(isActive)}>Domů</NavLink>
            <NavLink to="/Abouth" className={({ isActive }) => ActiveName(isActive)}>O nás</NavLink>
            {user === null ? (
              <>
                <NavLink to="/Login" className={({ isActive }) => ActiveName(isActive)}>Přihlášení</NavLink>
                <NavLink to="/Register" className={({ isActive }) => ActiveName(isActive)}>Registrace</NavLink>
                <NavLink to="/Launched_test_student" className={({ isActive }) => ActiveName(isActive)}>Připojit se !</NavLink>
              </>
            ) : (
              <>
                <NavLink to="/AllTests" className={({ isActive }) => ActiveName(isActive)}>Moje testy</NavLink>
                <ProfilNavbar user={user} logOut={logOut} />
              </>
            )}
          </div>
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-navbar-links">
            <NavLink to="/" className="link" onClick={closeMobileMenu}>Domů</NavLink>
            <NavLink to="/Abouth" className="link" onClick={closeMobileMenu}>O nás</NavLink>
            {user === null ? (
              <>
                <NavLink to="/Login" className="link" onClick={closeMobileMenu}>Přihlášení</NavLink>
                <NavLink to="/Register" className="link" onClick={closeMobileMenu}>Registrace</NavLink>
                <NavLink to="/Launched_test_student" className={({ isActive }) => ActiveName(isActive)}>Připijit se !</NavLink>
              </>
            ) : (
              <>
                <NavLink to="/AllTests" className="link" onClick={closeMobileMenu}>Moje testy</NavLink>
                <button className="link logout-button" onClick={handleLogout}>Odhlásit se</button>
              </>
            )}
          </div>
        )}
      </nav>
    </header>
  );
};

const ActiveName = (isActive) => {
  return isActive ? "link active" : "link";
};

export default Navbar;
