const environments = {
    development: {
        API_URL: 'http://localhost:5000', // URL pro REST API
        WEBSOCKET_URL: 'ws://localhost:5000', // URL pro WebSocket
        WEB_URL: 'http://localhost:3000',
    },
    testing: {
        API_URL: 'https://test-server.example.com',
        WEBSOCKET_URL: 'wss://test-server.example.com',
        WEB_URL: 'http://localhost:3000',
    },
    production: {
        API_URL: 'https://testora-8df0fa40587d.herokuapp.com',
        WEBSOCKET_URL: 'wss://testora-8df0fa40587d.herokuapp.com', // Produkční WebSocket URL
        WEB_URL: 'https://testora.app',
    },
};

// Vybereme prostředí na základě NODE_ENV nebo použijeme 'development' jako výchozí
const ENV =  'production';

const config = environments[ENV];

export default config;