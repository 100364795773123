// Normal_test_session.js
import React, { useState,  useContext} from 'react';
import { useParams } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaCheckCircle } from 'react-icons/fa';
import '../../../style/Launched_test/Normal_test_session_student.css';
import { WebSocketContext } from '../../../Backend/webSocketContext';

const Normal_test_session = () => {
    const { idTestSession, idStudent, studentName } = useParams();
    const test = JSON.parse(localStorage.getItem("test")) || [];
    const testName = JSON.parse(localStorage.getItem("TestName")) || [];
    const { ws } = useContext(WebSocketContext);
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [answers, setAnswers] = useState(Array(test.length).fill(''));
    const [transition, setTransition] = useState('');
    const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
    const [testSubmitted, setTestSubmitted] = useState(false);

    const handleAnswerChange = (e) => {
        const newAnswers = [...answers];
        newAnswers[activeQuestion] = e.target.value;
        setAnswers(newAnswers);
    };

    const handlePrevious = () => {
        if (activeQuestion > 0) {
            setTransition('slide-out-right');
            setTimeout(() => {
                setActiveQuestion((prev) => prev - 1);
                setTransition('slide-in-left');
            }, 300);
        }
    };

    const handleNext = () => {
        if (activeQuestion < test.length - 1) {
            setTransition('slide-out-left');
            setTimeout(() => {
                setActiveQuestion((prev) => prev + 1);
                setTransition('slide-in-right');
            }, 300);
        }
    };

    const handleSubmitTest = () => {
        setShowSubmitConfirm(true);
    };

    const confirmSubmitTest = () => {
        setShowSubmitConfirm(false);
        setTestSubmitted(true);

        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({
                type: 'submitTest',
                sessionID: idTestSession,
                studentID: idStudent,
                studentName: studentName,
                answers: answers,
            }));
        }
    };

    if (test.length === 0) {
        return <div className="no-questions">Žádné otázky nenalezeny.</div>;
    }

    return (
        <section className="launched_test">
            {testSubmitted ? (
                <div className="submission-confirmation">
                    <FaCheckCircle className="check-icon" />
                    <h2>Test odeslán k opravě!</h2>
                </div>
            ) : (
                <>
                    <div className="test_header">
                        <h2>{testName}</h2>
                        <p>Jméno: {studentName}</p>
                    </div>

                    <button
                        className={`button_previous ${activeQuestion === 0 ? 'disabled' : ''}`}
                        onClick={handlePrevious}
                        disabled={activeQuestion === 0}
                    >
                        <FaArrowLeft />
                    </button>

                    <div className={`question-container ${transition}`}>
                        <div className="question_header">
                            <p>Otázka {activeQuestion + 1} z {test.length}</p>
                        </div>
                        <div className="question_body">
                            <h1 className="question_text">{test[activeQuestion]?.question}</h1>
                            <textarea
                                value={answers[activeQuestion]}
                                onChange={handleAnswerChange}
                                placeholder="Zadejte odpověď zde"
                            />
                        </div>
                        <div className="question_footer">
                            <p>Body: {test[activeQuestion]?.points}</p>
                        </div>
                    </div>

                    <button
                        className={`button_next ${activeQuestion === test.length - 1 ? 'disabled' : ''}`}
                        onClick={handleNext}
                        disabled={activeQuestion === test.length - 1}
                    >
                        <FaArrowRight />
                    </button>

                    <div className="submit-container">
                        <button className="submit-button" onClick={handleSubmitTest}>
                            Odeslat test
                        </button>
                    </div>

                    {showSubmitConfirm && (
                        <div className="submit-confirm-modal">
                            <div className="modal-content">
                                <h2>Opravdu chcete odeslat test?</h2>
                                <button className="confirm-button" onClick={confirmSubmitTest}>Ano, odeslat</button>
                                <button className="cancel-button" onClick={() => setShowSubmitConfirm(false)}>Zrušit</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </section>
    );
};

export default Normal_test_session;
