import { auth } from '../firebase/configfirebase';

export const fetchData = async (url, bodyParams = {}) => {
  try {
    if (!auth.currentUser) {
      throw new Error("Uživatel není přihlášen!");
    }

    const token = await auth.currentUser.getIdToken();

    const requestBody = { ...bodyParams };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (response.status === 404) {
      return { data: [], error: "No data found" };
    } else if (response.status === 500) {
      throw new Error("Komunikace se serverem selhala");
    } else if (!response.ok) {
      throw new Error(`Unexpected status: ${response.status}`);
    } else {
      const result = await response.json();
      return { data: result, error: null };
    }
  } catch (err) {
    return { data: [], error: err.message };
  }
};
