import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FaGripLines } from "react-icons/fa";

const SortableItem = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? "none" : transition,
    zIndex: isDragging ? 2 : 1,
    opacity: isDragging ? 0.9 : 1,
  };

  const className = `One-Questions ${isDragging ? "dragging" : ""}`;

  return (
    <div ref={setNodeRef} style={style} className={className}>
      {/* Drag Handle */}
      <div className="drag-handle" {...listeners} {...attributes}>
        <FaGripLines />
      </div>
      {/* Content */}
      {children}
    </div>
  );
};

export default SortableItem;
