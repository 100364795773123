import React from 'react'

const one_connected_student = (props) => {
  return (
    <div className='one-student'>
      <h1 className='studen-name'>{props.oneStudent}</h1>
    </div>
  )
}

export default one_connected_student
