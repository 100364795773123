import React, { useState, useContext } from "react";
import { AuthContext } from "../firebase/AuthContext";
import "../style/ProfilePage.css";
import {
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { uploadImageToStorage } from "../utils/firebaseUtils";

const ProfilePage = () => {
  const { user } = useContext(AuthContext);
  const [editModeProfile, setEditModeProfile] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName || "Uživatel");
  const [profileImage, setProfileImage] = useState(user?.photoURL || "/default-avatar.png");
  const [language, setLanguage] = useState("cs");
  const [theme, setTheme] = useState("light");
  const [newPassword, setNewPassword] = useState(""); // Pro změnu hesla
  const [currentPassword, setCurrentPassword] = useState(""); // Pro ověření hesla

  if (!user) {
    return <p>Uživatel není přihlášen. Přihlaste se, abyste mohli spravovat svůj profil.</p>;
  }

  // Nahrání profilového obrázku
  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Nahrávám soubor:", file.name);
  
      try {
        const uploadedUrl = await uploadImageToStorage(file, `profile-images/${user.uid}/${file.name}`);
        console.log("Úspěšné nahrání:", uploadedUrl);
  
        setProfileImage(uploadedUrl);
        await updateProfile(user, { photoURL: uploadedUrl });
        console.log("Profilový obrázek byl aktualizován.");
      } catch (error) {
        console.error("Chyba při nahrávání:", error);
      }
    }
  };

  // Uložení změn profilu
  const handleSaveChanges = async () => {
    try {
      if (displayName.trim() === "") {
        alert("Jméno nemůže být prázdné.");
        return;
      }
      await updateProfile(user, { displayName, photoURL: profileImage });
      console.log("Změny byly úspěšně uloženy.");
      setEditModeProfile(false);
    } catch (error) {
      console.error("Chyba při ukládání změn:", error);
    }
  };

  // Změna hesla
  const handlePasswordChange = async () => {
    try {
      if (!currentPassword || !newPassword) {
        alert("Vyplňte obě hesla.");
        return;
      }
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential); // Reautorizace
      await updatePassword(user, newPassword); // Aktualizace hesla
      alert("Heslo bylo úspěšně změněno!");
      setCurrentPassword("");
      setNewPassword("");
    } catch (error) {
      console.error("Chyba při změně hesla:", error);
      alert("Nepodařilo se změnit heslo. Zkontrolujte své aktuální heslo.");
    }
  };

  // Aktivace dvoufázového ověření (2FA)
  const handleEnable2FA = () => {
    console.log("Aktivace 2FA není dosud implementována.");
  };

  // Přidání přihlášení přes Google/Facebook
  const handleAddSocialLogin = () => {
    console.log("Přidání sociálního přihlášení není dosud implementováno.");
  };

  return (
    <section className="profile-page">
      <h1>Můj profil</h1>
      <div className="profile-section">
        <div className="profile-avatar">
          <img src={profileImage} alt="Profil" />
          {editModeProfile && (
            <input
              type="file"
              accept="image/*"
              onChange={handleProfileImageChange}
              className="profile-avatar-upload"
            />
          )}
        </div>
        <div className="profile-info"> 
          <p>
            <strong>E-mail:</strong> {user?.email}
          </p>
          <p>
            <strong>Datum registrace:</strong> {user?.metadata?.creationTime}
          </p>
        </div>
        {editModeProfile ? (
          <input
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        ) : (
          <p>
            <strong>Jméno:</strong> {displayName}
          </p>
        )}
        <div className="button-group">
          {editModeProfile ? (
            <>
              <button className="cancel-button" onClick={() => setEditModeProfile(false)}>
                Zrušit
              </button>
              <button className="save-profile-button" onClick={handleSaveChanges}>
                Uložit
              </button>
            </>
          ) : (
            <button className="edit-button" onClick={() => setEditModeProfile(true)}>
              Upravit profil
            </button>
          )}
        </div>
      </div>
      <div className="profile-section">
        <h3>Nastavení účtu</h3>
        <label>
          Aktuální heslo:
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </label>
        <label>
          Nové heslo:
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </label>
        <div className="button-group">
          <button onClick={handlePasswordChange}>Změna hesla</button>
        </div>
        <div className="button-group">
          <button onClick={handleEnable2FA}>Aktivace dvoufázového ověření</button>
          <button onClick={handleAddSocialLogin}>Přidání Google/Facebook přihlášení</button>
        </div>
      </div>
      <div className="profile-section">
        <h3>Personalizace</h3>
        <label>
          Jazyk:
          <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value="cs">Čeština</option>
            <option value="en">Angličtina</option>
          </select>
        </label>
        <label>
          Téma:
          <select value={theme} onChange={(e) => setTheme(e.target.value)}>
            <option value="light">Světlé</option>
            <option value="dark">Tmavé</option>
          </select>
        </label>
      </div>
    </section>
  );
};

export default ProfilePage;
