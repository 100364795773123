import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../style/componenty/ProfilNavbar.css"; // Styl

const ProfilNavbar = ({ user, logOut }) => {
  const [menuOpen, setMenuOpen] = useState(false); // Stav pro zobrazení menu
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logOut(); // Odhlášení
    navigate("/"); // Přesměrování na domovskou stránku
  };

  return (
    <div
      className="profil-navbar"
      onClick={() => setMenuOpen(!menuOpen)}
      title="Uživatelský profil"
    >
      <div className="profil-info">
        <img
          src={user?.photoURL || "/default-avatar.png"}
          alt="Profil"
          className="profil-avatar"
        />
        <span className="profil-name">{user?.displayName || "Uživatel"}</span>
      </div>
      {menuOpen && (
        <div className="profil-dropdown">
          <button onClick={() => navigate("/profile")}>Profil</button>
          <button onClick={handleLogout}>Odhlásit se</button>
        </div>
      )}
    </div>
  );
};

export default ProfilNavbar;
