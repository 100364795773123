import config from "../config";
import { useState, useEffect } from "react";
import { fetchData } from "../Backend/fetchData";
import CompTests from "../Component/Parts/CompTests";
import "./../style/Testy.css";
import { SlPlus } from "react-icons/sl";

const AllTests = () => {
  const [Tests, setTests] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const deleteTest = (id) => {
    setTests(Tests.filter((OneTest) => OneTest.id !== id));
    try {
      fetchData(`${config.API_URL}/api/POST/remove-test`, {id})
    } catch(err) {
      console.log(err)
    }

}

  useEffect(() => {
    fetchData(`${config.API_URL}/api/POST/tests`)
      .then(({ data, error }) => {
        const sortedData = (data || []).sort((a, b) => new Date(b.lastUpdated._seconds) - new Date(a.lastUpdated._seconds));
        setTests(sortedData); 
        setError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleAddTest = () => {
    fetchData(`${config.API_URL}/api/POST/new_test`)
      .then(({ data, error }) => {
        if (data) {
          setTests(prevTests => [data, ...prevTests]); 
        }
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section>
      <p>
        {loading ? "Načítání dat..." : ""}
        {error ? `${error}` : ""}
      </p>
      <h1>Všechny testy</h1>
      <div className="All-Tests">
        {Tests.map((OneTest) => (
          <CompTests OneTest={OneTest} key={OneTest.id} deleteTest={deleteTest}/>
        ))}
        <div key="new" className="One-Questions newQuestion-box" onClick={handleAddTest}>
          <SlPlus className="newQuestion" />
        </div>
      </div>
    </section>
  );
};

export default AllTests;