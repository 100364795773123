import '../style/Abouth.css';

const Abouth = () => {
  return (
    <section className="about-container">
      <div className="about-hero">
        <h1 className="about-title">O Testoře</h1>
        <p className="about-description">
          Testora je inteligentní nástroj navržený pro učitele, aby ušetřil čas při opravování testů a zefektivnil celý proces hodnocení.
          Využíváme moderní technologie a umělou inteligenci, abychom zjednodušili práci učitelů a poskytli studentům rychlou a kvalitní zpětnou vazbu.
        </p>
      </div>
      <div className="about-content">
        <p>
          Jako pedagog se zkušenostmi ve výuce matematiky, fyziky a informatiky jsem vytvořil <strong>Testoru</strong> z potřeby zefektivnit každodenní úkoly učitelů.
          Naším cílem je ulehčit vám práci a přinést revoluci do vzdělávání s pomocí umělé inteligence.
        </p>
        <p>
          Děkujeme, že jste se rozhodli být součástí této inovativní cesty za lepším a modernějším vzděláváním. Testora je tu, aby vám pomohla každý den.
        </p>
      </div>
    </section>
  );
};


export default Abouth;
