import React, { useState } from "react";
import config from "../../config";
import { FaSave, FaCaretRight } from "react-icons/fa";
import { fetchData } from "../../Backend/fetchData";
import { useNavigate } from "react-router-dom";
import { auth } from '../../firebase/configfirebase';

const CompTestEdit = ({ testInfo, questions, dispatch }) => {
  const [testName, setTestName] = useState(testInfo.TestName);
  const [testDescription, setTestDescription] = useState(testInfo.TestDescription);
  const [testTime, setTestTime] = useState(parseInt(testInfo.TestTime, 10));
  const [isEditing, setIsEditing] = useState({
    name: false,
    description: false,
    time: false,
  });
  const navigate = useNavigate();

  const handleDoubleClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
    handleSaveLocal(field);
  };

  const handleSaveLocal = (field) => {
    let updatedData = {};
    if (field === "name") {
      updatedData.TestName = testName;
    } else if (field === "description") {
      updatedData.TestDescription = testDescription;
    } else if (field === "time") {
      updatedData.TestTime = testTime;
    }
    dispatch({ type: "UPDATE_TEST_INFO", payload: updatedData });
  };

  const handleSave = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      if (!token) {
        console.error("Uživatel není přihlášen!");
        return;
      }

      const payload = {
        token,
        testID: testInfo.testID,
        testInfo: { TestName: testName, TestDescription: testDescription, TestTime: testTime },
        questions: questions.map((q, index) => ({
          id: q.id,
          question: q.question,
          description: q.description,
          answers: q.answers,
          points: q.points,
          order: index + 1,
        })),
        sessions: [], // Přidejte sessions, pokud jsou potřeba
      };

      const response = await fetch(
        `${config.API_URL}/api/POST/tests_save`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const newData = await fetchData(
          `${config.API_URL}/api/POST/questions`,
          { test_ID: testInfo.testID }
        );
        if (newData.data) {
          const sortedQuestions = newData.data.questions
            .map((q, index) => ({
              ...q,
              order: q.order ?? index + 1,
            }))
            .sort((a, b) => a.order - b.order);
          // Aktualizace otázek v reduceru
          dispatch({
            type: "FETCH_SUCCESS",
            payload: {
              ...testInfo,
              questions: sortedQuestions,
              sessions: newData.data.sessions,
            },
          });
        }
        alert("Test byl úspěšně uložen.");
      } else {
        console.error("Nepodařilo se uložit data na backend.");
        alert("Nepodařilo se uložit test. Zkuste to prosím znovu.");
      }
    } catch (error) {
      console.error("Chyba při ukládání dat na backend:", error);
      alert("Došlo k chybě při ukládání testu.");
    }
  };

  const handleLaunchTest = async () => {
    try {
        const response = await fetchData(
            `${config.API_URL}/api/POST/teacher_test_launch_POST`,
            { testID: testInfo.testID }
        );

        if (response.error) {
            console.error("Error Launch test:", response.error);
            alert("Došlo k chybě při spouštění testu.");
            return;
        }

        const { sessionID } = response.data;

        // Příprava dat pro uložení do localStorage
        const testData = {
            testInfo: {
                TestName: testName,
                TestDescription: testDescription,
                TestTime: testTime,
                testID: testInfo.testID,
            },
            questions: questions.map((q, index) => ({
                id: q.id,
                question: q.question,
                description: q.description,
                answers: q.answers,
                points: q.points,
                order: index + 1,
            })),
            sessions: response.data.sessions || [],
        };

        // Uložení dat do localStorage s klíčem sessionID
        localStorage.setItem(sessionID, JSON.stringify(testData));

        navigate(`/Launched_test_teacher/${testInfo.testID}/${sessionID}`);
    } catch (error) {
        console.error("Unexpected error launching test:", error);
        alert("Došlo k nečekané chybě při spouštění testu.");
    }
};

  return (
    <div className="Test-header">
      <div>
        {isEditing.name ? (
          <input
            type="text"
            value={testName}
            onChange={(e) => setTestName(e.target.value)}
            onBlur={() => handleBlur("name")}
            onDoubleClick={() => handleDoubleClick("name")}
          />
        ) : (
          <h2
            className="Test-header-Name"
            onDoubleClick={() => handleDoubleClick("name")}
          >
            {testName}
          </h2>
        )}

        {isEditing.description ? (
          <input
            type="text"
            value={testDescription}
            onChange={(e) => setTestDescription(e.target.value)}
            onBlur={() => handleBlur("description")}
            onDoubleClick={() => handleDoubleClick("description")}
          />
        ) : (
          <p
            className="Test-header-description"
            onDoubleClick={() => handleDoubleClick("description")}
          >
            {testDescription}
          </p>
        )}

        {isEditing.time ? (
          <input
            type="number"
            value={testTime}
            onChange={(e) => setTestTime(parseInt(e.target.value, 10) || 0)}
            onBlur={() => handleBlur("time")}
          />
        ) : (
          <p
            className="Test-header-Time"
            onDoubleClick={() => handleDoubleClick("time")}
          >
            {testTime === 0 ? "Neomezený" : `${testTime} minut`}
          </p>
        )}

        <h3>Otázky</h3>
      </div>
      <div className="test-button-save">
        <button className="btn-save" onClick={handleSave}>
          <FaSave /> Uložit
        </button>
        <button className="btn-save" onClick={handleLaunchTest}>
          <FaCaretRight /> Spustit
        </button>
      </div>
    </div>
  );
};

export default React.memo(CompTestEdit);
